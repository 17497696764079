import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

import CookieConsentBanner from '../../components/CookieConsentBanner';
import Footer from '../../components/v2/Footer';
import Header from '../../components/v2/header/Header';
import JungleWarsLayout from '../../components/v2/jungle_wars/JungleWarsLayout';
import Layout from '../../components/v2/Layout';

const JungleWarsPage = () => {
  const { t } = useTranslation();

  return (
    <Layout title={t('Jungle Wars')}>
      <Header showSubHeader="junglewars" redirectToBuyNft />
      <CookieConsentBanner />
      <JungleWarsLayout />
      <Footer />
    </Layout>
  );
};

export default JungleWarsPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
