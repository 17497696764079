import classNames from 'classnames';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import scrollTo from 'gatsby-plugin-smoothscroll';
import React, { useState } from 'react';

import { AnalyticsEventLocations, AnalyticsEvents } from '../../../analytic-events';
import * as Analytics from '../../../services/analytics-utils';

import JungleLinkButton from './JungleLinkButton';

const ScrollIcon = (
  <svg viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.11904 6.41935C1.62699 6.97849 1.62699 7.88503 2.11904 8.44417L11.9194 19.5806C12.2009 19.9006 12.5826 20.0375 12.9498 19.9913C13.317 20.0375 13.6986 19.9006 13.9802 19.5806L23.7805 8.44417C24.2726 7.88503 24.2726 6.97849 23.7805 6.41935C23.2885 5.86022 22.4907 5.86022 21.9986 6.41935L12.9498 16.7019L3.90091 6.41935C3.40886 5.86022 2.61109 5.86022 2.11904 6.41935Z"
      fill="white"
    />
  </svg>
);

const heroVideoClassNames = 'min-h-[500px] md:min-h-[55vw]';

const Hero = () => {
  const { t } = useTranslation();
  const [firstVideoLoaded, setFirstVideoLoaded] = useState(false);

  return (
    <section className={classNames('relative', heroVideoClassNames)}>
      <div className="absolute top-0 bottom-0 left-0 right-0 w-full h-full bg-black">
        <div className="relative w-full bg-switch-video h-full min-h-full object-cover">
          <video
            onTimeUpdate={() => {
              if (!firstVideoLoaded) {
                setTimeout(() => {
                  setFirstVideoLoaded(true);
                }, 400);
              }
            }}
            className={classNames(
              firstVideoLoaded ? 'opacity-30' : 'opacity-0',
              'w-full',
              'hidden',
              'sm:block',
              'h-full',
              'min-h-screen',
              'object-cover',
              'absolute',
              'top-0',
              'bottom-0',
              'left-0',
              'right-0',
              'z-0',
              heroVideoClassNames
            )}
            playsInline
            autoPlay
            muted
            loop
          >
            <source className="object-cover" src="/video/paima-hero-video-01-1000kbs.mp4" type="video/mp4" />
          </video>
          <video
            className={classNames(
              'opacity-30',
              'w-full',
              'block',
              'sm:hidden',
              'min-h-full',
              'object-cover',
              'absolute',
              'top-0',
              'bottom-0',
              'left-0',
              'right-0'
            )}
            playsInline
            autoPlay
            muted
            loop
          >
            <source className="object-cover" src="/video/paima-hero-video-mobile-01.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
      <div className="absolute top-0 bottom-0 left-0 right-0 w-full h-full flex flex-col items-center justify-center z-50">
        <div className="md:max-w-[650px] xl:max-w-[1000px] mx-auto px-[16px] py-[40px]">
          <div className="flex flex-col items-end justify-between">
            <h2 className="font-junglewars text-[20px] leading-6 xl:text-[40px] xl:leading-[48px] font-black text-jungle-primary-default text-center">
              <Trans>
                Jungle Wars really inspires me as a developer to think about how I can leverage web3 concepts for my
                game ideas. A simple yet powerful game, and a sign of things to come!
              </Trans>
            </h2>
            <h3 className="font-bold font-junglewars text-[#FFCFAF] text-[18px] lg:text-[24px] leading-4 lg:leading-8 mt-[12px]">
              - <Trans>Sebastien Guillemot</Trans>
            </h3>
          </div>
          <div className="mt-[32px] lg:mt-[64px] max-w-[260px] mx-auto">
            <JungleLinkButton
              target="_blank"
              href="https://paimastudios.com/junglewars/play"
              text={t('Play for free')}
              onClick={() => {
                Analytics.event(AnalyticsEvents.PLAY_FOR_FREE, '', AnalyticsEventLocations.JUNGLE_WARS_HERO);
              }}
            />
          </div>
          <div className="mt-[32px] lg:mt-[64px] w-[26px] h-[26px] mx-auto">
            <a
              href="#jungle-news"
              className="w-[26px] h-[26px] cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                scrollTo('#jungle-news');
              }}
            >
              {ScrollIcon}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
