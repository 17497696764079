import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

import CharacterCarousel from './CharacterCarousel/CharacterCarousel';
import FAQ from './FAQ';
import Hero from './Hero';
import JoinTheRumble from './JoinTheRumble/JoinTheRumble';
import JoinUs from './JoinUs';

const JungleWarsLayout = () => {
  const { t } = useTranslation();

  return (
    <>
      <Hero />
      <JoinTheRumble />
      <CharacterCarousel />
      <div className="bg-[url('/images/junglewars-bottom-background.webp')] bg-no-repeat bg-bottom bg-cover jungle-oval-clip pt-40 md:pt-[15vw] lg:pt-[10vw] relative -mt-[12vw] md:-mt-[14vw] z-50">
        <FAQ
          questions={[
            {
              question: t('What is Jungle Wars NFT Rumble?'),
              answer: t(
                "Jungle Wars is a causal PvP on-chain game which is the first to show off the potential of trustless web3 gaming. Thanks to being built with Paima Engine, the game enables a brand new gameplay style where all of a player's moves are recorded onto and replayable from blockchain data. Furthermore Jungle Wars highlights a brand new gaming primitive, Stateful NFTs, where the player's stats are accrued into an ever-evolving NFT as the player plays the game."
              )
            },
            {
              question: t('Do I need to have an NFT to play?'),
              answer: t('No, you do not need an NFT to play, and there is no upfront cost to get started.')
            },
            {
              question: t('What are NFTs like Volcaneers for in the game?'),
              answer: t(
                'Using a Stateful NFT such as Volcs can enhance your experience by allowing you to track and showcase your game record on the blockchain. This record, which includes your wins, losses, and draws, can be accessed by anyone on the blockchain and can evolve and gain value as you continue to play the game. Additionally, having a well-established game record on the blockchain may get you the opportunity to take part in up and coming tournaments!'
              )
            },
            {
              question: t('Will I be able to play from Cardano, Astar, Ethereum, or Algorand mainnet?'),
              answer: (
                <Trans
                  i18nKey="In Q1 2023 we released a new piece of technology called Paima Whirlpool which allows users on Cardano Mainnet, Astar network, and Ethereum to play Paima Studio games using their native wallets! In the future Paima Whirlpool will also continue to be upgraded to allow for further multi-chain integrations including the likes of Algorand, and more!"
                  components={[<strong />]}
                />
              )
            },
            {
              question: t('Why is Metamask asking me to pay 3+ mADA for gas?!'),
              answer: t(
                'This happens because you have no mADA in your wallet and metamask gas estimation runs into trouble. Simply add mADA to your wallet and the fees will be properly calculated going forward.'
              )
            },
            {
              question: t('Why do I need to sign each transaction in Jungle Wars NFT Rumble?'),
              answer: (
                <Trans
                  i18nKey="Jungle Wars is pioneering trustless Web3 gaming, meaning all actions in Jungle Wars are stored on-chain. As such, players must submit their moves to the blockchain via a transaction, with a current cost of around $0.005 per move. This ensures that all game actions are trustless and verifiable, offering a level of decentralization not possible with traditional, off-chain or Web2.5 games. In the near future with the upcoming Paima Batcher, this cost will be able to be lowered even further, and eventually the requirement of signing each transaction will be able to be removed completely!"
                  components={[<strong />]}
                />
              )
            },
            {
              question: t('How do the lobbies work in Jungle Wars?'),
              answer: t(
                'Jungle Wars lets players create customized lobbies for matchmaking. Lobby creators can choose game parameters such as time limit, number of rounds, lobby visibility, and more! There is no limit to the number of lobbies a player can create or join, meaning you can feel just like a chess grandmaster by playing 50 games at the same time!'
              )
            },
            {
              question: t('How can I rewatch or replay a match in Jungle Wars NFT Rumble on Milkomeda?'),
              answer: t(
                'In the main menu, click on the Play icon to the left of the Settings button in the bottom right hand side of the screen. This will open a new modal where you can paste in the Lobby ID and click Rewatch to see the action for yourself!'
              )
            },
            {
              question: t('What wallets can I use to play?'),
              answer: (
                <Trans
                  i18nKey="Currently, Paima Studio games can be played through Cardano wallets, EVM wallets, and Astar/Polkadot wallets. In the near future we will also be adding support for Algorand wallets, and potentially other wallets/networks as well!"
                  components={[
                    <a
                      className="underline cursor-pointer"
                      href="https://dcspark.github.io/milkomeda-documentation/cardano/for-end-users/configuring-metamask"
                      target="_blank"
                    />
                  ]}
                />
              )
            }
          ]}
        />
        <JoinUs />
      </div>
    </>
  );
};

export default JungleWarsLayout;
