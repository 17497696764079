import classNames from 'classnames';
import { Trans } from 'gatsby-plugin-react-i18next';
import React from 'react';

import JungleWarsSlider from '../JungleWarsSlider';
import RumbleTiles from './RumbleTiles';

const JoinTheRumble = () => {
  return (
    <section
      className={classNames(
        "bg-[url('/images/junglewars-rumble-background-mobile.webp')]",
        "md:bg-[url('/images/junglewars-rumble-background.webp')]",
        'bg-cover',
        'bg-no-repeat',
        'bg-[center_-20px]',
        'pt-[30vw]',
        'md:pt-[17vw]',
        'lg:pt-[14vw]',
        'pb-120',
        'md:pb-[15vw]',
        'jungle-oval-clip',
        'relative',
        '-mt-[12vw]',
        'z-30'
      )}
    >
      <div className="container-slim scroll-mt-[175px] md:scroll-mt-[155px] lg:scroll-mt-[135px]" id="jungle-news">
        <div className="md:mb-[80px]">
          <JungleWarsSlider />
        </div>
        <h1
          id="about"
          className="scroll-mt-[210px] md:scroll-mt-[190px] lg:scroll-mt-[170px] text-40 leading-[56px] md:text-50 md:leading-[64px] lg:text-80 lg:leading-[88px] text-jungle-primary-default font-junglewars font-black text-center"
        >
          <Trans
            i18nKey="Join the Jungle Wars: NFT Rumble"
            components={[<span className="block text-30 md:text-40 lg:text-60" />]}
          />
        </h1>
        <p className="mt-[36px] text-center max-w-[820px] mx-auto text-jungle-primary-default text-[16px] leading-7 md:text-[20px] md:leading-8 lg:text-[24px] lg:leading-9">
          <Trans>
            Jungle Wars: NFT Rumble is a fun casual Player Vs. Player game which acts as a first proof-of-concept of the
            tech that will unlock the trustless Web3 gaming future.
          </Trans>
        </p>
        <div className="mt-[64px]">
          <RumbleTiles />
        </div>
      </div>
    </section>
  );
};

export default JoinTheRumble;
