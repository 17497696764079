import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

import { AnalyticsEventLocations, AnalyticsEvents } from '../../../../analytic-events';
import * as Analytics from '../../../../services/analytics-utils';
import { getLocalizedLink } from '../../../../utils/locale';

import JungleLinkButton from '../JungleLinkButton';

interface TileProps {
  iconUrl: string;
  title: string;
  buttonText: string;
  buttonHref?: string;
  comingSoon?: boolean;
}

const Tile = ({ iconUrl, title, buttonHref, buttonText, comingSoon = false }: TileProps) => {
  return (
    <li className="max-w-[256px]">
      <div className="bg-[#ECE5C9] rounded-[12px] jungle-box-shadow-inset-quarter w-[256px] h-[256px] flex items-center justify-center border-[6px] border-[#FFFBE7]">
        <img src={iconUrl} alt="" className="w-[220px] h-[100px]" />
      </div>
      <div className="md:min-h-[60px] my-[16px] md:my-[32px] flex items-start justify-center text-center font-junglewars text-[20px] leading-8 uppercase font-black text-jungle-dark-dark px-[16px]">
        <h2>{title}</h2>
      </div>
      {comingSoon ? (
        <JungleLinkButton href="#" text={buttonText} disabled comingSoonTooltip />
      ) : (
        <JungleLinkButton
          href={buttonHref}
          text={buttonText}
          target="_self"
          onClick={() => {
            Analytics.event(AnalyticsEvents.SEE_LEADERBOARD, '', AnalyticsEventLocations.JUNGLE_WARS_NFT_RUMBLE);
          }}
        />
      )}
    </li>
  );
};

const RumbleTiles = () => {
  const { t, i18n } = useTranslation();

  return (
    <ul className="flex flex-col md:flex-row md:flex-wrap items-center justify-center gap-y-[56px]">
      <div className="md:flex-grow-1 md:flex-shrink-0 md:basis-[50%] md:flex md:justify-center lg:basis-1/3">
        <Tile
          iconUrl="/images/junglewars-nft-icon.png"
          title={t('Get on top of the Leaderboard')}
          buttonHref={getLocalizedLink('/junglewars/leaderboard', i18n.language)}
          buttonText={t('See Leaderboard')}
        />
      </div>
      <div className="md:flex-grow-1 md:flex-shrink-0 md:basis-[50%] md:flex md:justify-center lg:basis-1/3">
        <Tile
          iconUrl="/images/junglewars-pvp-icon.png"
          title={t('Compete In Tournaments')}
          buttonText={t('Soon')}
          comingSoon
        />
      </div>
      <div className="md:flex-grow-1 md:flex-shrink-0 md:basis-[50%] md:flex md:justify-center lg:basis-1/3">
        <Tile
          iconUrl="/images/junglewars-no1-icon.png"
          title={t('Trade your top ranking NFTs')}
          buttonText={t('Soon')}
          comingSoon
        />
      </div>
    </ul>
  );
};

export default RumbleTiles;
