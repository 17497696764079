import classNames from 'classnames';
import { Link } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

import CoomingSoonTooltip from '../../Tooltip';

interface JungleLinkButtonProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  text: string;
  disabled?: boolean;
  comingSoonTooltip?: boolean;
}

const JungleLinkButton = ({
  href: originalHref,
  text,
  target = '_self',
  disabled = false,
  comingSoonTooltip,
  ...rest
}: JungleLinkButtonProps) => {
  const { t } = useTranslation();

  const href = originalHref ?? '#';

  const classes = classNames(
    'text-center',
    'w-full',
    'bg-jungle-primary-default',
    'block',
    'ranking-box-shadow-inset-button',
    'border-4',
    !disabled ? 'border-[#feae10]' : 'border-transparent',
    disabled ? 'cursor-not-allowed' : 'cursor-pointer',
    'rounded-[12px]'
  );

  const LinkInnerElement = (
    <span className="block w-full ranking-box-shadow-inset-negative px-[12px] py-[12px] rounded-[8px] border border-[#7A7B6F]">
      <span className="jungle-orange-text font-black font-junglewars text-[20px] leading-[30px] uppercase tracking-[0.065em]">
        {text}
      </span>
    </span>
  );

  if (target === '_self') {
    return comingSoonTooltip ? (
      <CoomingSoonTooltip classes="w-full" text={t('Coming Soon')} backgroundColor="light">
        <Link
          to={href}
          target={target}
          {...rest}
          className={classes}
          onClick={(e) => {
            if (href === '#') {
              e.preventDefault();
            }
          }}
        >
          {LinkInnerElement}
        </Link>
      </CoomingSoonTooltip>
    ) : (
      <Link
        to={href}
        target={target}
        {...rest}
        className={classes}
        onClick={(e) => {
          if (href === '#') {
            e.preventDefault();
          }
        }}
      >
        {LinkInnerElement}
      </Link>
    );
  }

  return (
    <a href={href} target={target} {...rest} className={classes}>
      {LinkInnerElement}
    </a>
  );
};

export default JungleLinkButton;
