import classNames from 'classnames';
import { Trans } from 'gatsby-plugin-react-i18next';
import React, { ReactElement, useRef, useState } from 'react';

const Arrow = (
  <svg viewBox="0 0 33 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_i_1829_5050)">
      <path
        d="M30.9693 14.5324C33.6442 16.0704 33.6442 19.9296 30.9693 21.4676L6.74795 35.3949C3.48534 37.2709 -0.32669 34.0129 1.01819 30.4979L5.2531 19.4294C5.60525 18.509 5.60525 17.491 5.2531 16.5706L1.01819 5.50209C-0.326686 1.98708 3.48535 -1.27093 6.74796 0.605077L30.9693 14.5324Z"
        fill="#FEFCE7"
      />
    </g>
    <defs>
      <filter
        id="filter0_i_1829_5050"
        x="0.740845"
        y="0.0566406"
        width="32.2346"
        height="35.8867"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1829_5050" />
      </filter>
    </defs>
  </svg>
);

enum Character {
  ANACONDA = 'Anaconda',
  GORILLA = 'Gorilla',
  JAGUAR = 'Jaguar',
  MACAV = 'Macaw',
  PIRANHA = 'Piranha',
  SLOTH = 'Sloth',
  NFT = 'Your NFT'
}

interface CharacterData {
  index: number;
  name: Character;
  gifPath: string;
  imagePath: string;
  videoPath: string;
  description?: string | ReactElement;
}

const characters: CharacterData[] = [
  {
    index: 0,
    name: Character.ANACONDA,
    gifPath: '/gifs/anaconda.gif',
    imagePath: '/images/jungle-character-anaconda.webp',
    videoPath: '/video/anaconda_short.mp4',
    description: (
      <Trans>
        Anaconda is the slithering, secretive serpent of the forest. Whilst large and sometimes slow, Anaconda is the
        most calculating of all animals. Others would be wise not to judge Anaconda on looks alone.
      </Trans>
    )
  },
  {
    index: 1,
    name: Character.GORILLA,
    gifPath: '/gifs/gorilla.gif',
    imagePath: '/images/jungle-character-gorilla.webp',
    videoPath: '/video/gorilla_short.mp4',
    description: (
      <Trans>
        Gorilla is a friendly fellow most of the time. But Gorilla is still the largest and strongest creature in the
        jungle who will stop at nothing to defend his territory and his family troop!
      </Trans>
    )
  },
  {
    index: 2,
    name: Character.JAGUAR,
    gifPath: '/gifs/jaguar.gif',
    imagePath: '/images/jungle-character-jaguar.webp',
    videoPath: '/video/jaguar_short.mp4',
    description: (
      <Trans>
        Sleek, stealthy, and the most deadly of all the creatures in the jungle. Jaguar’s skills are famous throughout
        and should never be overlooked in battle!
      </Trans>
    )
  },
  {
    index: 3,
    name: Character.MACAV,
    gifPath: '/gifs/macav.gif',
    imagePath: '/images/jungle-character-macav.webp',
    videoPath: '/video/macav_short.mp4',
    description: (
      <Trans>
        Bright and captivating; don’t let Macaw’s colors and aerial abilities dazzle you too much! Macaw is
        well-practiced with his shot and will stop at nothing in the Jungle Wars!
      </Trans>
    )
  },
  {
    index: 4,
    name: Character.PIRANHA,
    gifPath: '/gifs/piranha.gif',
    imagePath: '/images/jungle-character-piranha.webp',
    videoPath: '/video/piranha_short.mp4',
    description: (
      <Trans>
        Piranha is known for being part of a large and ferocious family, but, be warned, when alone Piranha is twice as
        aggressive as usual
      </Trans>
    )
  },
  {
    index: 5,
    name: Character.SLOTH,
    gifPath: '/gifs/sloth.gif',
    imagePath: '/images/jungle-character-sloth.webp',
    videoPath: '/video/sloth_short.mp4',
    description: (
      <Trans>
        Typically thought of as slow and lazy, with a love for hanging upside down. Sloth’s desire and skill to climb to
        his rightful place at the top of the jungle’s food chain should not be underestimated!
      </Trans>
    )
  },
  {
    index: 6,
    name: Character.NFT,
    gifPath: '/images/jungle-character-your-nft-full.webp',
    imagePath: '/images/jungle-character-your-nft.webp',
    videoPath: '/video/your_nft_short.mp4',
    description: (
      <Trans>
        The Volcaneers are the first in a revolutionary new type of NFT that we’re pioneering called Stateful NFTs.
        Choose your Volc and start your adventure into the world of Paima Games.
      </Trans>
    )
  }
];

const CAROUSEL_SWIPE_MIN_DISTANCE = 50;

const CharacterCarousel = () => {
  const smallCharactersWrapper = useRef<HTMLDivElement>(null);

  const [currentCharacter, setCurrentCharacter] = useState<CharacterData>(characters[0]);

  const [touchStart, setTouchStart] = useState<number | null>(null);
  const [touchEnd, setTouchEnd] = useState<number | null>(null);

  const handleSmallCharactersAutoscroll = (chosenCharacter: CharacterData) => {
    if (!smallCharactersWrapper.current || !chosenCharacter) {
      return;
    }

    const scrollAdvanceAmount = 64 + 12;
    const safeScrollOffset = characters.indexOf(chosenCharacter) * scrollAdvanceAmount;

    smallCharactersWrapper.current.scrollLeft = safeScrollOffset;
  };

  const handlePreviousClick = () => {
    const matchingCharacter =
      characters[currentCharacter.index === 0 ? characters.length - 1 : currentCharacter.index - 1];

    setCurrentCharacter(matchingCharacter);
    handleSmallCharactersAutoscroll(matchingCharacter);
  };

  const handleNextClick = () => {
    const matchingCharacter =
      characters[currentCharacter.index === characters.length - 1 ? 0 : currentCharacter.index + 1];

    setCurrentCharacter(matchingCharacter);
    handleSmallCharactersAutoscroll(matchingCharacter);
  };

  const handleSelectorClick = (chosenCharacter: Character) => {
    const characterData = characters.find((character) => character.name === chosenCharacter);

    if (!characterData) {
      return;
    }

    setCurrentCharacter(characterData);
  };

  const onTouchStart = (e: React.TouchEvent) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e: React.TouchEvent) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) {
      return;
    }

    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > CAROUSEL_SWIPE_MIN_DISTANCE;
    const isRightSwipe = distance < -CAROUSEL_SWIPE_MIN_DISTANCE;

    if (isLeftSwipe || isRightSwipe) {
      if (isRightSwipe) {
        handlePreviousClick();
      }
      if (isLeftSwipe) {
        handleNextClick();
      }
    }
  };

  return (
    <section className="bg-[url('/images/junglewars-character-background.jpg')] bg-cover bg-no-repeat bg-center pb-120 md:pb-[20vw] lg:pb-[250px] pt-[10vw] relative -mt-[14vw] jungle-oval-clip z-40">
      <div className="container-slim pt-80 lg:pt-[8vw]">
        <h2 className="font-junglewars text-[30px] leading-[40px] md:text-[50px] md:leading-[64px] lg:text-[80px] lg:leading-[96px] text-jungle-dark-dark font-black text-center">
          <Trans>Choose Your Champion</Trans>
        </h2>
        <div className="max-w-[80vw] md:max-w-[60vw] lg:max-w-[800px] mx-auto mt-24 text-jungle-dark-dark">
          <p className="text-center text-20 md:text-24 leading-8">
            <Trans>
              Every animal of the jungle has a heart full of fight and will never back down. Each with their own tribe
              supporting them, which will you aid in the Jungle Wars: NFT Rumble?
            </Trans>
          </p>
        </div>
        <div className="pt-64">
          <div
            className="overflow-x-scroll overflow-y-hidden pb-8 md:overflow-x-hidden md:overflow-y-hidden md:pb-0 relative z-20"
            ref={smallCharactersWrapper}
          >
            <div className="flex flex-nowrap items-center justify-center gap-12 md:gap-24 lg:gap-40 min-w-[520px]">
              {characters.map((character) => {
                const isCurrent = character.name === currentCharacter.name;

                return (
                  <div
                    key={character.name}
                    className="hover:cursor-pointer"
                    onClick={() => handleSelectorClick(character.name)}
                  >
                    <div
                      className={classNames(
                        isCurrent ? 'bg-[#9FE1FA]' : 'bg-[#FEFCE7]/[0.8]',
                        'jungle-box-shadow-inset-blue',
                        'rounded-[12px]',
                        'w-[64px] h-[64px] lg:w-[104px] lg:h-[104px]'
                      )}
                    >
                      <img
                        src={character.imagePath}
                        alt={character.name}
                        className="w-[64px] h-[64px] lg:w-[104px] lg:h-[104px] relative -top-[5px]"
                      />
                    </div>
                    <h4
                      className={classNames(
                        'font-junglewars',
                        'text-jungle-dark-dark',
                        'text-center',
                        isCurrent ? 'font-black' : 'font-bold',
                        'tracking-wider',
                        'uppercase',
                        'mt-16',
                        'text-[10px]',
                        'leading-[10px]',
                        'md:text-[12px]',
                        'md:leading-[12px]'
                      )}
                    >
                      <Trans>{character.name}</Trans>
                    </h4>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flex flex-col-reverse lg:flex-row mt-[88px]">
            <div className="lg:pr-40 mt-40 lg:mt-0 flex flex-col items-center justify-center lg:justify-start lg:items-start">
              <h3 className="font-junglewars text-jungle-dark-dark text-[20px] lg:text-36 font-black tracking-wider uppercase">
                {currentCharacter.name}
              </h3>
              {currentCharacter.description && (
                <p className="mt-[16px] text-[16px] leading-[24px] text-center lg:text-left md:max-w-[600px]">
                  {currentCharacter.description}
                </p>
              )}
              <div className="w-full pt-[54.25%] rounded-[12px] border-jungle-primary-default border-[6px] mt-24 hidden lg:block relative">
                <video
                  className="absolute top-0 bottom-0 left-0 right-0 h-full object-cover rounded-[8px] p-[1px]"
                  playsInline
                  autoPlay
                  muted
                  loop
                  key={currentCharacter.name}
                >
                  <source src={currentCharacter.videoPath} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="relative w-[280px] h-[280px] sm:w-[320px] sm:h-[320px] md:w-[512px] md:h-[512px] mx-auto">
              <button
                onClick={() => handlePreviousClick()}
                className="absolute top-0 bottom-0 left-20 m-auto z-30 w-[33px] h-[36px] rotate-180"
              >
                {Arrow}
              </button>
              <div className="relative">
                <img
                  src="/images/piedestal.svg"
                  className={classNames(
                    'w-[444px]',
                    'h-[284px]',
                    'absolute',
                    'bottom-0',
                    'left-0 lg:left-[30px]',
                    'right-0',
                    'mx-auto',
                    'z-10'
                  )}
                />
                <div className="w-[280px] h-[280px] sm:w-[320px] sm:h-[320px] md:w-[512px] md:h-[512px] relative">
                  {characters.map((character) => {
                    const isCurrent = character.name === currentCharacter.name;

                    return (
                      <img
                        key={character.name}
                        src={character.gifPath}
                        alt={character.name}
                        className={classNames(
                          'absolute',
                          'left-0',
                          'right-0',
                          'top-0',
                          'bottom-0',
                          'w-full',
                          'h-full',
                          'z-20',
                          '-top-[80px] lg:-top-[50px]',
                          isCurrent ? 'block' : 'hidden'
                        )}
                        onTouchStart={onTouchStart}
                        onTouchMove={onTouchMove}
                        onTouchEnd={onTouchEnd}
                      />
                    );
                  })}
                </div>
              </div>
              <button
                onClick={() => handleNextClick()}
                className="absolute top-0 bottom-0 right-20 m-auto z-30 w-[33px] h-[36px]"
              >
                {Arrow}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CharacterCarousel;
