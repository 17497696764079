import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { MediumBlogPostsResponse } from '../../../services/types';
import { fetchMediumBlogPosts, formatPublicationDate } from '../../../services/utils';

const PER_PAGE = 3;

interface ArticleProps {
  title: string;
  pubDate: string;
  thumbnail: string;
  link: string;
}

const Slide = ({ title, pubDate, thumbnail, link }: ArticleProps) => {
  return (
    <a
      href={link}
      target="_blank"
      className="block rounded-[12px] border-4 border-[#80A5AE] relative cursor-pointer group"
    >
      <img src={thumbnail} alt="" className="rounded-[8px]" />
      <div className="absolute bottom-0 left-0 right-0 bg-[#221508]/[0.8] p-[12px] rounded-bl-[12px] rounded-br-[12px]">
        <h3 className="text-white text-[16px] leading-6 group-hover:underline">{title}</h3>
        <p className="text-[#808080] text-[12px] mt-[4px]">{formatPublicationDate(pubDate)}</p>
      </div>
    </a>
  );
};

const JungleWarsSlider = () => {
  const [data, setData] = useState<MediumBlogPostsResponse | null>(null);

  useEffect(() => {
    (async () => {
      const data = await fetchMediumBlogPosts();
      setData(data);
    })();
  }, []);

  if (!data || data.status !== 'ok') {
    return null;
  }

  return (
    <div
      className={classNames(
        'container',
        'pt-40',
        'pb-64',
        'md:pb-40',
        'jungle-slider',
        data.items.length < PER_PAGE && 'jungle-slider--centered'
      )}
    >
      <Splide
        tag="section"
        options={{
          autoWidth: true,
          rewind: true,
          gap: 0,
          pagination: false,
          focus: 0,
          arrows: true
          // perPage: PER_PAGE
          // arrows: data.items.length > PER_PAGE
        }}
      >
        {[...data.items, ...data.items, ...data.items].map((item, index) => (
          <SplideSlide key={index}>
            <Slide {...item} />
          </SplideSlide>
        ))}
      </Splide>
    </div>
  );
};

export default JungleWarsSlider;
